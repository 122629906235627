export const dropdownMenuProduct = [
    {
        to: "/camera-ai",
        blank: false,
        lable: 'VNS CamAI',
    },
    {
        to: "/speech-to-text",
        blank: false,
        lable: 'Speech to Text',
    },
    {
        to: "/his",
        blank: false,
        lable: 'Giải pháp y tế',
    },
    {
        to: "/tham-dinh-gia",
        blank: false,
        lable: 'Thẩm định giá',
    },
    {
        to: "/cong-chung-giao-dich-dam-bao",
        blank: false,
        lable: 'Công chứng, đăng ký giao dịch bảo đảm, lấy cà vẹt xe, mượn/xuất tài sản',
    },
    {
        to: "/quan-ly-tai-san-the-chap",
        blank: false,
        lable: 'Quản lý tài sản thế chấp, hàng tồn kho',
    },
    {
        to: "/core-gia-lap-cho-giao-duc",
        blank: false,
        lable: 'Core banking giả lập cho giáo dục',
    },
    {
        to: "http://edocs.vinorsoft.com/about/",
        blank: true,
        lable: 'VNS Edocs',
    },
]